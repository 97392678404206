const resources = {
  en: {
    translation: {
      "nav.lang": "En",
      "nav.title.products": "Products",
      "nav.title.about": "About",
      "nav.title.contact": "Contact",
      "nav.title.company": "COMPANY",
      "nav.title.product": "PRODUCT",
      "nav.title.joinus": "Join us",
      "nav.download": "Download",
      "carousel.main.title": "Empowering Your Digital Future",
      "carousel.voxis.title":
        '"Stay Connected, Anytime, Anywhere with our Chat App."',
      "carousel.pigeon.title": "Power up your customer support with Spark",
      "carousel.moreAbout": "More About",
      "carousel.download": "Download",
      "carousel.joinus.main.title": "Join us",
      "carousel.joinus.sub.title": "Be part of us!",
      "products.title": "Our Products",
      "products.download": "Download",
      "products.learnMore": "Learn more...",
      "products.moreAbout": "More about",
      "voxis.title": "PULSE",
      "voxis.desc": '"Connect and Chat Anytime, Anywhere."',
      "pigeon.title": "SPARK",
      "pigeon.desc": "Experience hassle-free customer service with us.",
      "about.title": "All about us",
      "about.desc":
        "An IT company is a business that specializes in providing technology-related products and services to its clients. Such companies can range in size from small startups to large corporations and can offer a wide range of services, including software development, web development, cybersecurity, cloud computing, data analysis, and digital marketing.",
      "figure.desc":
        "This extra text just helps here. As long as he's here everything will be fine. This text complements the previous one",
      "figure.title.projects": "Projects",
      "figure.title.files": "Files",
      "figure.title.works": "Works",
      "figure.title.orders": "Orders",
      "figure.title.awwards": "Awwards",
      "contact.title": "Contact us",
      "contact.desc.getInTouch": "Get in touch with us today!",
      "contact.desc.feelFree":
        "Feel free to reach out if you have any questions.",
      "footer.directory.company": "Company",
      "footer.directoryLink.aboutUs": "About us",
      "footer.directoryLink.documentation": "Documentation",
      "footer.directoryLink.joinUs": "Join us",
      "footer.directory.product": "Product",
      "footer.directoryLink.voxis": "PULSE",
      "footer.directoryLink.pigeon": "SPARK",
      "newsletter.desc": "Join the newsletter and read the new posts first",
      "newsletter.submit": "Submit",
    },
  },
  zh: {
    translation: {
      "nav.lang": "中",
      "nav.title.products": "产品",
      "nav.title.about": "关于",
      "nav.title.contact": "联系",
      "nav.title.company": "公司",
      "nav.title.product": "产品",
      "nav.title.joinus": "加入我们",
      "nav.download": "下载",
      "carousel.main.title": "赋予您的数字未来",
      "carousel.voxis.title": "随时随地使用我们的聊天应用程序保持联系。",
      "carousel.pigeon.title": "使用Spark提升您的客户支持",
      "carousel.moreAbout": "更多关于",
      "carousel.download": "下载",
      "products.title": "我们的产品",
      "products.download": "下载",
      "products.learnMore": "了解更多...",
      "products.moreAbout": "更多关于",
      "voxis.title": "PULSE",
      "voxis.desc": "随时随地连接和聊天。",
      "pigeon.title": "SPARK",
      "pigeon.desc": "与我们一起体验无忧的客户服务。",
      "about.title": "关于我们",
      "about.desc":
        "IT公司是专门为客户提供与技术相关的产品和服务的企业。这样的公司可以从小型初创企业到大型公司不等，并可以提供广泛的服务，包括软件开发、网站开发、网络安全、云计算、数据分析和数字营销等。",
      "figure.desc":
        "这是额外的文本，只是在这里起辅助作用。只要他在这里，一切都会很好。这段文字补充了前面的内容",
      "figure.title.projects": "项目",
      "figure.title.files": "文件",
      "figure.title.works": "作品",
      "figure.title.orders": "订单",
      "figure.title.awwards": "奖项",
      "contact.title": "联系我们",
      "contact.desc.getInTouch": "今天就与我们取得联系！",
      "contact.desc.feelFree": "如有任何问题，请随时联系我们。",
      "footer.directory.company": "公司",
      "footer.directoryLink.aboutUs": "关于我们",
      "footer.directoryLink.documentation": "文档",
      "footer.directoryLink.joinUs": "加入我们",
      "footer.directory.product": "产品",
      "footer.directoryLink.voxis": "PULSE",
      "footer.directoryLink.pigeon": "SPARK",
      "newsletter.desc": "加入通讯，第一时间阅读最新文章",
      "newsletter.submit": "提交",
    },
  },
  jp: {
    translation: {
      "nav.lang": "日",
      "nav.title.products": "製品",
      "nav.title.about": "だいたい",
      "nav.title.contact": "コンタクト",
      "nav.title.company": "会社",
      "nav.title.product": "製品",
      "nav.title.joinus": "参加する",
      "nav.download": "ダウンロード",
      "carousel.main.title": "デジタル未来を支える",
      "carousel.voxis.title":
        "いつでもどこでも我々のチャットアプリでつながりましょう。",
      "carousel.pigeon.title": "Sparkで顧客サポートを強化する",
      "carousel.moreAbout": "さらに詳しく",
      "carousel.download": "ダウンロード",
      "products.title": "当社の製品",
      "products.download": "ダウンロード",
      "products.learnMore": "詳細を見る...",
      "products.moreAbout": "さらに詳しく",
      "voxis.title": "PULSE",
      "voxis.desc": "いつでもどこでも接続してチャットできます。",
      "pigeon.title": "SPARK",
      "pigeon.desc": "手間のかからないカスタマーサービスをぜひご体験ください。",
      "about.title": "私たちについて",
      "about.desc":
        "IT企業は、クライアントにテクノロジー関連の製品やサービスを提供することを専門とするビジネスです。このような企業は、小規模なスタートアップから大企業まで様々な規模があり、ソフトウェア開発、Web開発、サイバーセキュリティ、クラウドコンピューティング、データ分析、デジタルマーケティングなど幅広いサービスを提供することができます。",
      "figure.desc":
        "この追加のテキストがここにあると、何でも大丈夫です。このテキストは前のテキストを補完します。",
      "figure.title.projects": "プロジェクト",
      "figure.title.files": "ファイル",
      "figure.title.works": "作品",
      "figure.title.orders": "注文",
      "figure.title.awwards": "賞",
      "contact.title": "お問い合わせ",
      "contact.desc.getInTouch": "お問い合わせはこちらから",
      "contact.desc.feelFree":
        "ご不明点がありましたら、お気軽にお問い合わせください。",
      "footer.directory.company": "会社",
      "footer.directoryLink.aboutUs": "会社概要",
      "footer.directoryLink.documentation": "ドキュメンテーション",
      "footer.directoryLink.joinUs": "採用情報",
      "footer.directory.product": "製品",
      "footer.directoryLink.voxis": "PULSE",
      "footer.directoryLink.pigeon": "SPARK",
      "newsletter.desc": "ニュースレターに参加して最新記事を最初に読みましょう",
      "newsletter.submit": "送信",
    },
  },
  kr: {
    translation: {
      "nav.lang": "한",
      "nav.title.products": "제품",
      "nav.title.about": "에 대한",
      "nav.title.contact": "연락하다",
      "nav.title.company": "회사",
      "nav.title.product": "제품",
      "nav.title.joinus": "참여하기",
      "nav.download": "다운로드",
      "carousel.main.title": "당신의 디지털 미래를 선도합니다",
      "carousel.voxis.title":
        "우리의 채팅 앱으로 언제 어디서나 연결 유지하세요.",
      "carousel.pigeon.title": "Spark으로 고객 지원 업그레이드",
      "carousel.moreAbout": "자세히 알아보기",
      "carousel.download": "다운로드",
      "products.title": "우리의 제품",
      "products.download": "다운로드",
      "products.learnMore": "자세히 알아보기...",
      "products.moreAbout": "자세히 알아보기",
      "voxis.title": "PULSE",
      "voxis.desc": "언제 어디서나 연결하고 채팅하십시오.",
      "pigeon.title": "SPARK",
      "pigeon.desc": "저희와 함께 번거로움 없는 고객 서비스를 경험해 보세요.",
      "about.title": "우리에 대해",
      "about.desc":
        "IT 회사는 클라이언트에게 기술 관련 제품 및 서비스를 제공하는 전문 비즈니스입니다. 이러한 회사는 소규모 스타트업에서 대기업까지 다양한 규모를 가지며, 소프트웨어 개발, 웹 개발, 사이버 보안, 클라우드 컴퓨팅, 데이터 분석, 디지털 마케팅 등 폭넓은 서비스를 제공할 수 있습니다.",
      "figure.desc":
        "여기에 추가 텍스트가 있으면 모든 것이 괜찮습니다. 이 텍스트는 이전 텍스트를 보완합니다.",
      "figure.title.projects": "프로젝트",
      "figure.title.files": "파일",
      "figure.title.works": "작품",
      "figure.title.orders": "주문",
      "figure.title.awwards": "수상 경력",
      "contact.title": "연락처",
      "contact.desc.getInTouch": "문의하기",
      "contact.desc.feelFree": "질문이 있으시면 언제든지 문의해 주세요.",
      "footer.directory.company": "회사",
      "footer.directoryLink.aboutUs": "회사 소개",
      "footer.directoryLink.documentation": "문서",
      "footer.directoryLink.joinUs": "채용 정보",
      "footer.directory.product": "제품",
      "footer.directoryLink.voxis": "PULSE",
      "footer.directoryLink.pigeon": "SPARK",
      "newsletter.desc": "뉴스레터에 가입하여 새로운 게시물을 먼저 읽어보세요",
      "newsletter.submit": "제출",
    },
  },
};

const rerender = () => {
  // Translate the content using jquery-i18next plugin
  $("body").localize();

  // Check if the selected language is 'kr' and modify the font size of the specific div
  // if (i18next.language === "kr") {
  //     $("#selected-language").css("font-size", "smaller");
  // } else {
  //     $("#selected-language").css("font-size", ""); // Reset font size if not 'kr'
  // }
};

$(function () {
  // Initialize i18next with the cached language or English as the default language
  i18next.init(
    {
      lng: localStorage.getItem("language") || "en",
      resources: resources,
    },
    function () {
      // Use jquery-i18next plugin to enable translation on the selected elements
      jqueryI18next.init(i18next, $);
      rerender();
    }
  );

  // Add click event listener to language toggle links
  $("#language-toggle a").on("click", function (e) {
    e.preventDefault();

    // Remove the "active" class from all language links
    $("#language-toggle a").removeClass("active");

    // Add the "active" class to the clicked language link
    $(this).addClass("active");

    // Get the language from the data-lang attribute of the clicked link
    var lang = $(this).data("lang");

    // Change the language using i18next's changeLanguage method
    i18next.changeLanguage(lang, function (err, t) {
      if (err)
        return console.log("something went wrong loading translation", err);

      // Store the selected language in local storage for caching
      localStorage.setItem("language", lang);

      rerender();
    });
  });

  // Set the initial active language based on the cached language in local storage
  var cachedLanguage = localStorage.getItem("language");
  if (cachedLanguage) {
    $('#language-toggle a[data-lang="' + cachedLanguage + '"]').addClass(
      "active"
    );
  }
});
